import loadable from '@loadable/component'
import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const ContactCall = loadable(() => import('../ContactCall'))


const CatalogsPageTemplate = (props) => {
  const { title, content, contentComponent } = props
  const PageContent = contentComponent || Content

  return (
    <>
    <section className='hero is-primary is-bold' id="catalogs-head" style={{minHeight:'70%',backgroundImage:'url(/img/katalogi.svg)',backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'top right',backgroundAttachment: `fixed`}}>
      <div className='hero-body'>
      <div className='container section'>
        <div className='columns section'>
          <div>
            <h1 className='title' style={{fontSize:'50px',marginTop:'50px'}}>
              {title}
            </h1>
            <h2 className='subtitle'>
            Kits of eco-friendly products and promotional gadgets.
            </h2>
          </div>
        </div>
      </div>
      </div>
    </section>
    <nav className="breadcrumbs-nav section">
    <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{color:'#23C495'}}><b>{title}</b></span>
    </nav>

    <div style={{padding:'0px',marginBottom:'0px',marginTop:'-20px',marginLeft:'5%',marginRight:'5%',textAlign:'center'}}>
      <h3 className='title'> We invite you to view <b style={{color:'#23C495'}}>the catalogues</b>. </h3>
      <h4 className='subtitle'> You are sure to find something for yourself or as a gift for your loved ones. </h4>
    </div>

      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>

              <br />

                <PageContent className='content' content={content} />

            </div>
          </div>
        </div>
      </section>
      <br />

      <br />
      <div className='bottomUi' style={{textAlign:'center',marginBottom:'30px'}}>
      <Link className='button-green' style={{fontSize:'15px'}} to='/offer/'> Discover our offer&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link className='button-green' style={{fontSize:'15px'}} to='/portfolio/'> See portfolio&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link className='button-green' style={{fontSize:'15px'}} to='/about-us/'> Get to know us better&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
      </div>


      <br />
        <ContactCall />
    </>
  )
}

CatalogsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default CatalogsPageTemplate
